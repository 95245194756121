import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useTheme } from '@emotion/react'
import { useHistory } from 'react-router-dom'
import { fetchComponentExternalResources } from 'jmt-fetch-component-resources'

import { PageContainer } from '../../../components/base/layouts/PageContainer'
import { PageTitle } from '../../../components/base/texts/PageTitle'
import { useShallowEqualSelector } from '../../../utils/custom-hooks/useShallowEqualSelector'
import { useTranslation } from '../../../utils/custom-hooks/useTranslation'

export const MembershipApplication = ({ title }) => {
  const { token } = useShallowEqualSelector((state) => state.userInfoReducer)
  const {
    apiUrl,
    short,
  } = useShallowEqualSelector((state) => state.moduleConfigReducer)
  const theme = useTheme()
  const history = useHistory()
  const { t } = useTranslation()

  useEffect(() => {
    fetchComponentExternalResources({
      manageSubUrl: 'm-anage.com.membership-application/m-anage.com.component.membership-application',
      version: 'v1.3.16',
      // isProdEnv: false,
      files: [
        {
          tag: 'script',
          name: 'main',
          ending: 'js',
        },
      ],
    })
  }, [])

  useEffect(() => {
    const goToMembership = () => {
      history.push('/membership')
    }
    window.addEventListener('m-anage.com.component.membership-application.done', goToMembership)

    return () => {
      window.removeEventListener('m-anage.com.component.membership-application.done', goToMembership)
    }
  }, [])

  return (
    <PageContainer>
      <PageTitle>
        {t(`${title}_Page-Title`)}
      </PageTitle>
      <div
        id="m-anage.com.component.membership-application"
        apiurl={apiUrl}
        context="Society"
        language="en-GB"
        theme={JSON.stringify(theme)}
        societyshort={short}
        accesstoken={token}
      />
    </PageContainer>
  )
}

MembershipApplication.propTypes = {
  title: PropTypes.string.isRequired,
}
